var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "after-order-container",
    on: {
      "click": function click($event) {
        return _vm.$emit('click', _vm.order);
      }
    }
  }, [_vm.order.accept_finished_datetime ? _c('div', {
    staticClass: "finished-logo"
  }, [_c('van-image', {
    attrs: {
      "width": "1.78667rem",
      "height": "1.6rem",
      "src": require('@/assets/images/yiyanshou.png')
    }
  })], 1) : _vm._e(), _vm.order.accept ? _c('van-cell', {
    staticClass: "accept-info",
    attrs: {
      "title": "验收人",
      "value": _vm.order.accept.name
    }
  }) : _vm._e(), _vm.order.accept_finished_datetime ? _c('van-cell', {
    staticClass: "accept-info",
    attrs: {
      "title": "验收时间",
      "value": _vm.order.accept_finished_datetime
    }
  }) : _vm._e(), _vm.order.user ? _c('van-cell', {
    staticClass: "accept-info",
    attrs: {
      "title": "退货人",
      "value": _vm.order.user.name
    }
  }) : _vm._e(), _vm.order.user ? _c('van-cell', {
    staticClass: "accept-info",
    attrs: {
      "title": "退货时间",
      "value": _vm.order.created_at
    }
  }) : _vm._e(), _c('div', {
    staticClass: "after-order-container__content"
  }, [_c('van-row', {
    staticClass: "after-order-container__header"
  }, [_c('van-col', {
    attrs: {
      "span": "12"
    }
  }, [_vm._v(_vm._s(_vm.order.num))]), _c('van-col', {
    staticClass: "after-order-container__right-item",
    attrs: {
      "span": "12"
    }
  }, [_vm._v(_vm._s(_vm.order.created_at))])], 1), _c('van-row', {
    staticClass: "after-order-container__header2"
  }, [_c('van-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm.order.line ? _c('span', [_vm._v(_vm._s(_vm.order.line.name) + "线")]) : _c('span', [_vm._v("无线路")])]), _c('van-col', {
    staticClass: "after-order-container__right-item",
    attrs: {
      "span": "16"
    }
  }, [_vm._v(_vm._s(_vm.order.client.name))])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }