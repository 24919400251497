import request from '@/utils/request'
import FormData from 'form-data'

export function uploadImage(file) {
  const form = new FormData()
  form.append('image', file)
  return request({
    method: 'post',
    url: 'after-accept/upload',
    data: form,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export default {

  list(params) {
    return request({
      method: 'get',
      url: 'after-accept',
      params
    })
  },
  detail(params) {
    return request({
      method: 'get',
      url: 'after-accept/detail',
      params
    })
  },
  cancel(data) {
    return request({
      method: 'put',
      url: 'after-accept/cancel',
      data
    })
  },
  goods(data) {
    return request({
      method: 'put',
      url: 'after-accept/goods',
      data
    })
  },
  finish(data) {
    return request({
      method: 'put',
      url: 'after-accept/finish',
      data
    })
  }

}

export function changeAcceptImages(data) {
  return request({
    method: 'post',
    url: 'after-accept/images',
    data
  })
}

export function removeOtherGoods(data) {
  return request({
    method: 'post',
    url: 'after-accept/remove-other',
    data
  })
}

export function searchGoods(params) {
  return request({
    method: 'get',
    url: 'after-accept/search-goods',
    params
  })
}
