<template>
  <div class="after-order-container" @click="$emit('click', order)">
    <div v-if="order.accept_finished_datetime" class="finished-logo">
      <van-image width="1.78667rem" height="1.6rem" :src="require('@/assets/images/yiyanshou.png')" />
    </div>
    <van-cell v-if="order.accept" class="accept-info" title="验收人" :value="order.accept.name" />
    <van-cell v-if="order.accept_finished_datetime" class="accept-info" title="验收时间" :value="order.accept_finished_datetime" />
    <van-cell v-if="order.user" class="accept-info" title="退货人" :value="order.user.name" />
    <van-cell v-if="order.user" class="accept-info" title="退货时间" :value="order.created_at" />
    <div class="after-order-container__content">
      <van-row class="after-order-container__header">
        <van-col span="12">{{ order.num }}</van-col>
        <van-col class="after-order-container__right-item" span="12">{{ order.created_at }}</van-col>
      </van-row>
      <van-row class="after-order-container__header2">
        <van-col span="8">
          <span v-if="order.line">{{ order.line.name }}线</span>
          <span v-else>无线路</span>
        </van-col>
        <van-col class="after-order-container__right-item" span="16">{{ order.client.name }}</van-col>
      </van-row>
    </div>
    <!--<div class="after-order-container__arrow">-->
    <!--<van-icon name="arrow" class="van-cell__right-icon" />-->
    <!--</div>-->
  </div>
</template>

<script>
export default {
  name: 'AfterOrder',
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .after-order-container {
    position: relative;
    padding: 15px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(48, 48, 48, 0.07);
    /*display: flex;*/
    /*align-items: center;*/
    &__header {
      font-size: 15px;
      padding-bottom: 10px;
    }
    &__header2 {
      font-size: 15px;
    }
    &__content {
      font-size: 15px;
      // padding-top: 10px;
      /*width: 300px;*/
      /*margin-right: 3px;*/
    }
    &__right-item {
      text-align: right;
    }
    &__arrow {
      /*display: flex;*/
      /*height: 47px;*/
    }
  }
  .accept-info {
    padding: unset;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .finished-logo {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
</style>
