<template>
  <div class="accept-list-container">
    <my-nav-bar
      :title="lineName"
      left-text="返回"
      left-arrow
    />
    <van-cell title="选择日期" :value="formatCurrentDate" is-link center class="choose-date-cell" @click="showDateDialog = true" />
    <div class="list-wrap">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list
        ref="list"
        v-model="loading"
        :finished="finished"
        @load="getAfterOrders"
      >
        <after-order
          v-for="(order, index) in afterOrders"
          :key="index"
          :order="order"
          @click="handleAccept"
        />
      </van-list>
    </div>
    <van-popup v-model="showDateDialog" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @confirm="handleConfirm"
        @cancel="handleCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import afterAcceptApi from '@/api/after_accept'
import AfterOrder from './components/order'
import MyNavBar from '@/components/my-nav-bar'
import { toDateString } from '@/utils'

export default {
  name: 'AcceptList',
  components: { AfterOrder, MyNavBar },
  data() {
    return {
      lineName: this.$route.query.line_name,
      lineId: this.$route.query.line_id,
      type: this.$route.query.type,
      page: 0,
      limit: 10,
      afterOrders: [],
      loading: false,
      finished: false,
      currentDate: new Date(),
      showFilter: false,
      showEmpty: false,
      formatCurrentDate: '',
      showDateDialog: false
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
    // this.getAfterOrders()
  },
  mounted() {
    this.$eventBus.$on('acceptFinished', () => {
      // 重新获取数据
      this.page = 0
      this.afterOrders = []
      this.loading = true
      this.finished = false
      this.getAfterOrders()
    })
  },
  activated() {
    window.pageYOffset = this.$route.meta.scrollTop
    document.documentElement.scrollTop = this.$route.meta.scrollTop
    document.body.scrollTop = this.$route.meta.scrollTop
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/after-accept-detail') {
      this.$store.commit('rmKeepAlive', this.$options.name)
      this.$eventBus.$off('acceptFinished')
    }
    next()
  },
  methods: {
    getAfterOrders() {
      this.page++
      const params = {
        page: this.page,
        limit: this.limit,
        line_id: this.lineId ? this.lineId : 0,
        type: this.type,
        date: this.formatCurrentDate
      }
      afterAcceptApi.list(params).then(res => {
        this.afterOrders = this.afterOrders.concat(res.data.list)
        this.finished = res.data.list.length < this.limit
        this.loading = false
        this.showEmpty = this.afterOrders.length === 0
      })
    },
    // handleFilter(date) {

    // },
    handleAccept(order) {
      // this.showFilter = true
      // if (order.accept && order.accept.id !== this.$store.state.userInfo.id) {
      //   return
      // }
      this.$router.push({
        path: '/after-accept-detail',
        query: {
          line_id: this.lineId,
          order_id: order.id,
          is_finished: order.accept_finished_datetime ? 1 : 0,
          client_id: order.client_id
        }
      })
    },
    toRecordView() {
      this.$router.push({
        path: '/after-accept-record',
        query: {
          line_id: this.lineId,
          line_name: this.lineName
        }
      })
    },
    handleConfirm(value) {
      this.showDateDialog = false
      this.currentDate = value
      this.formatCurrentDate = toDateString(this.currentDate)
      this.page = 0
      this.loading = false
      this.finished = false
      this.afterOrders = []
      this.$refs.list.check()
    },
    handleCancel() {
      this.showDateDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .accept-list-container {
    padding: 0 0 5px;
    /*margin-bottom: 50px;*/
    .choose-date-cell{
      position: fixed;
      left: 0;
      right: 0;
      top: 46px;
      z-index: 99;
    }
    .list-wrap{
      margin-top: 95px;
      padding: 5px 15px;
    }
  }
</style>
